/* Reset CSS */

/* Установка отступов и отступов по умолчанию */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 элементы с новым дисплеем */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* Установка шрифта для input элементов, соответствующих наследуемым значениям font-family */
input,
select,
textarea {
  font: inherit;
}

/* Убираем стандартные стили списков */
ol,
ul {
  list-style: none;
}

/* Убираем стандартные стили для ссылок */
a {
  text-decoration: none;
  color: inherit;
}

/* Убираем стандартное выделение текста */
::selection {
  background: transparent;
  color: inherit;
}
body {
  box-sizing: border-box;
  background: #fcf8f3;
}
.container {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-right: 15px;
  padding-left: 15px;
}
@media screen and (min-width: 375px) {
  .container {
    max-width: 375px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px;
    padding-right: 64px;
    padding-left: 64px;
  }
}
